import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { call, callTk } from 'api';
import { setCurrentUser, unsetCurrentUser } from 'helpers/authentication';
import type { RootState } from 'store';
import { hideModal } from 'store/modals/modalsSlice';
import { fetchNotificationsCounter } from 'store/notifications/notificationsSlice';
import type { AppDispatch } from 'store/store';
import type { AppleLoginType, FacebookLoginType, LoginCallType, UserDetailType, UserLoginType } from 'types/user';
import { setUserId } from 'ui/design-base/Analytics';

const getPath = () => {
  let path = '/';
  if (localStorage.getItem('loginredirect') !== null) {
    path = localStorage.getItem('loginredirect') ?? '';
    if (path === '/accedi' || path === '/registrati') path = '/';
    localStorage.removeItem('loginredirect');
  }
  return path;
};

const postLoginDispatch = (dispatch: AppDispatch) => {
  dispatch(hideModal('login-form'));
  dispatch(hideModal('register-form'));
  setTimeout(() => dispatch(fetchNotificationsCounter()), 2000);
};

const postLoginActions = (user: UserDetailType) => {
  setCurrentUser(Object.assign({}, user, { user_id: user.id }));
  setUserId();
  window.location.href = getPath();
};

export const login = createAsyncThunk<
  LoginCallType,
  UserLoginType,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('sessions/login', async ({ email, password }, { rejectWithValue, dispatch }) => {
  const response = await call<LoginCallType>(
    'sessions',
    { method: 'POST', body: JSON.stringify({ email, password }) },
    (response) => {
      postLoginDispatch(dispatch);
      return response;
    },
    (error) => rejectWithValue(error),
  );
  return response;
});

export const facebookLogin = createAsyncThunk<
  LoginCallType,
  FacebookLoginType,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('sessions/facebook/facebookLogin', async (facebookData, { rejectWithValue, dispatch }) => {
  const response = await call<LoginCallType>(
    'sessions/facebook',
    { method: 'POST', body: JSON.stringify(facebookData) },
    (response) => {
      postLoginDispatch(dispatch);
      return response;
    },
    (error) => rejectWithValue(error),
  );
  return response;
});

export const facebookLimitedLogin = createAsyncThunk<
  LoginCallType,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('sessions/facebook/facebookLimitedLogin', async (facebookData, { rejectWithValue, dispatch }) => {
  const response = await call<LoginCallType>(
    'sessions/facebook_limited',
    { method: 'POST', body: JSON.stringify({ token: facebookData }) },
    (response) => {
      postLoginDispatch(dispatch);
      return response;
    },
    (error) => rejectWithValue(error),
  );
  return response;
});

export const appleLogin = createAsyncThunk<
  LoginCallType,
  AppleLoginType,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('sessions/apple-web-auth/appleLogin', async (appleData, { rejectWithValue, dispatch }) => {
  const response = await call<LoginCallType>(
    'sessions/apple-web-auth',
    { method: 'POST', body: JSON.stringify(appleData) },
    (response) => {
      postLoginDispatch(dispatch);
      return response;
    },
    (error) => rejectWithValue(error),
  );
  postLoginDispatch(dispatch);
  return response;
});

export const logout = createAsyncThunk<{ success: boolean }, string>(
  'sessions/logout',
  async (token, { rejectWithValue }) => {
    const response = await callTk<{ success: boolean }>(`sessions/${token}`, { method: 'DELETE' }, (error) =>
      rejectWithValue(error),
    );
    return response;
  },
);

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    error: '',
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(login.fulfilled, (_, action: PayloadAction<LoginCallType>) => {
      postLoginActions(action.payload.user);
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      const errors = action.payload as string[];
      state.error = errors.join('\n');
    });
    builder.addCase(facebookLogin.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(facebookLogin.fulfilled, (_, action: PayloadAction<LoginCallType>) => {
      postLoginActions(action.payload.user);
    });
    builder.addCase(facebookLogin.rejected, (state, action) => {
      state.loading = false;
      const errors = action.payload as string[];
      state.error = errors.join('\n');
    });
    builder.addCase(facebookLimitedLogin.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(facebookLimitedLogin.fulfilled, (_, action: PayloadAction<LoginCallType>) => {
      postLoginActions(action.payload.user);
    });
    builder.addCase(facebookLimitedLogin.rejected, (state, action) => {
      state.loading = false;
      const errors = action.payload as string[];
      state.error = errors.join('\n');
    });
    builder.addCase(appleLogin.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(appleLogin.fulfilled, (_, action: PayloadAction<LoginCallType>) => {
      postLoginActions(action.payload.user);
    });
    builder.addCase(appleLogin.rejected, (state, action) => {
      state.loading = false;
      const errors = action.payload as string[];
      state.error = errors.join('\n');
    });
    builder.addCase(logout.pending, (state) => {
      state.loading = true;
      state.error = '';
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.loading = false;
      unsetCurrentUser();
      window.location.href = '/accedi';
      setTimeout(() => {
        window.location.reload();
      }, 100);
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.loading = false;
      const errors = action.payload as string[];
      state.error = errors.join('\n');
    });
  },
});

export const authenticationReducer = authenticationSlice.reducer;
