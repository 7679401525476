import { useEffect, useState } from 'react';
import LoadAdvAdKaora from './LoadAdvAdKaora';

const LoadCookieConsentAndAds: React.FC<{ cookieLoaded: boolean }> = ({ cookieLoaded }) => {
  const [loadAds, setLoadAds] = useState(false);

  useEffect(() => {
    if (cookieLoaded) {
      setLoadAds(true);
    }
  }, [cookieLoaded]);
  return <>{loadAds && <LoadAdvAdKaora />}</>;
};

export default LoadCookieConsentAndAds;
