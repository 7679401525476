import { AdMob } from '@capacitor-community/admob';
import { Capacitor } from '@capacitor/core';
import { isCrawler, isCurrentUserPremium } from 'helpers/authentication';
import InitAdmob from 'helpers/InitAdmob';
import LoadCookieConsentAndAds from 'helpers/LoadCookieConsentAndAds';
import useAppLifecycle from 'helpers/useAppLifecycle';
import useAppUrlListener from 'helpers/useAppUrlListener';
import { usePushNotifications } from 'helpers/usePushNotifications';
import useScrollToTop from 'helpers/useScrollToTop';
import React, { type ReactElement } from 'react';
import useCookieConsent from './useCookieConsent';
const LoadSentry = React.lazy(() => import('helpers/LoadSentry'));

const WrapNewUi = ({ children }: { children: ReactElement | null }) => {
  usePushNotifications();
  useAppLifecycle();
  useAppUrlListener();
  useScrollToTop();
  const { cookieLoaded } = useCookieConsent();

  if (isCurrentUserPremium() && Capacitor.isNativePlatform()) {
    try {
      AdMob.removeBanner();
    } catch (e) {
      console.warn(e);
    }
  }

  return (
    <>
      <React.Suspense fallback={<></>}>
        <LoadSentry />
      </React.Suspense>
      {isCurrentUserPremium() || isCrawler() ? (
        <></>
      ) : Capacitor.isNativePlatform() ? (
        <InitAdmob />
      ) : (
        <LoadCookieConsentAndAds cookieLoaded={cookieLoaded} />
      )}
      {children}
    </>
  );
};

export default WrapNewUi;
