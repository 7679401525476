import { useScript } from 'helpers/utils';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const LoadAdvAdKaora = () => {
  const location = useLocation();

  const initialTimeoutRef = useRef<number | null>(null);
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    // Clear any existing initial timeout
    if (initialTimeoutRef.current) {
      clearTimeout(initialTimeoutRef.current);
    }

    // Set the initial timeout for the first execution after 1000ms
    initialTimeoutRef.current = setTimeout(() => {
      if (typeof window.adkSpaReload === 'function') {
        window.adkSpaReload();
        //console.log('adkSpaReload executed after initial delay.');

        // After the first execution, set up the recurring interval
        intervalRef.current = setInterval(() => {
          if (typeof window.adkSpaReload === 'function') {
            window.adkSpaReload();
            //console.log('adkSpaReload executed every 10 seconds.');
          }
        }, 10000);
      }
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts or location changes
    return () => {
      if (initialTimeoutRef.current) {
        clearTimeout(initialTimeoutRef.current);
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [location]); // Re-run effect when the location changes

  //useScript('//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4321911283255728');
  const loaded = useScript('https://cdn.adkaora.space/tennistalker/generic/prod/adk-init.js');
  return loaded ? <></> : <></>;
};

export default LoadAdvAdKaora;
