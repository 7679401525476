import { Capacitor } from '@capacitor/core';
import { useScript } from './utils';
import { isCrawler } from './authentication';
import { useEffect } from 'react';

const useCookieConsent = () => {
  const [cookieLoaded] = useScript(
    Capacitor.isNativePlatform() || isCrawler()
      ? undefined
      : 'https://app.termly.io/resource-blocker/a0c8b22d-95ff-4b10-a8c8-91e1403624f2?autoBlock=on',
  );

  useEffect(() => {
    if (cookieLoaded && !Capacitor.isNativePlatform() && !isCrawler()) {
      try {
        const termlyCache = localStorage.getItem('TERMLY_API_CACHE');
        if (termlyCache) {
          const parsedCache: { tcf_tc_string: { createdAt: number; value: string } } = JSON.parse(termlyCache);
          if (
            parsedCache?.tcf_tc_string?.value === '' ||
            new Date().getTime() - parsedCache.tcf_tc_string.createdAt > 1000 * 60 * 60 * 24 * 15
          ) {
            setTimeout(() => {
              if (typeof window.displayPreferenceModal === 'function') {
                localStorage.removeItem('TERMLY_API_CACHE');
                window.displayPreferenceModal();
              }
            }, 1000);
          }
        }
      } catch (e) {
        console.error('Error parsing TERMLY_API_CACHE:', e);
      }
    }
  }, [cookieLoaded]);
  return { cookieLoaded };
};

export default useCookieConsent;
